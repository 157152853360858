import { createRouter, createWebHistory } from 'vue-router';
import { trackRouter } from "vue-gtag-next";
import { initCJDeepLinks } from './cjDLGenerator.js'

import TheLanding from './components/pages/TheLanding';
const PrivacyPolicy = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "static-pages" */ 
  '@/components/pages/PrivacyPolicy'
);   
const TermConditions = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "static-pages" */ 
  '@/components/pages/TermsConditions'
);
const HelpContact = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "static-pages" */ 
  '@/components/pages/HelpContact'
);
const AdvertiseWithUs = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "static-pages" */ 
  '@/components/pages/AdvertiseWithUs'
);
const TheWishlist = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "profile-pages" */ 
  '@/components/pages/TheWishlist'
);
const ProfilePage = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "profile-pages" */ 
  '@/components/pages/ProfilePage'
);
const OrderHistory = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "profile-pages" */ 
  '@/components/pages/OrderHistory'
);
const CategoryPage = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "product-pages" */ 
  '@/components/pages/CategoryPage'
);
const SearchPage = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "product-pages" */ 
  '@/components/pages/SearchPage'
);
const ProductPage = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "product-pages" */ 
  '@/components/pages/ProductPage'
);
const BlogPage = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "article-pages" */ 
  '@/components/pages/BlogPage'
);
const BlogDetailsPage = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "article-pages" */ 
  '@/components/pages/BlogDetailsPage'
);
const NewsPage = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "article-pages" */ 
  '@/components/pages/NewsPage'
);

const GetCouponsPage = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "article-pages" */ 
  '@/components/pages/GetCouponsPage'
);
const CouponDetailsPage = () => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "article-pages" */ 
  '@/components/pages/CouponDetailsPage'
);CouponDetailsPage

import store from './store/index';

const router = createRouter({
    history: createWebHistory(),
    routes: [
      { 
        path: '/', 
        component: TheLanding
      },
      { 
        path: '/categories/:slug', 
        component: CategoryPage
      },
      { 
        path: '/product/:slug', 
        component: ProductPage, 
        meta: { 
          title: 'Product Details - Cigar Finder', 
          description: 'Get detailed information about our products, including features, reviews, and pricing.' 
        } 
      },
      { 
        path: '/profile', 
        component: ProfilePage,  
        meta: { 
          title: 'My Profile - Cigar Finder', 
          description: 'Manage your profile information, view order history, and update your account settings.', 
          needsAuth: true 
        }  
      },
      { 
        path: '/search-results', 
        component: SearchPage, 
        meta: { 
          title: 'Search Results - Cigar Finder', 
          description: 'Find the products, categories, or content you are searching for on our website.' 
        } 
      },
      { 
        path: '/wishlist', 
        component: TheWishlist,  
        meta: { 
          title: 'My Wishlist - Cigar Finder', 
          description: 'View and manage products that you have added to your wishlist.', 
          needsAuth: true 
        } 
      },
      { 
        path: '/orderhistory', 
        component: OrderHistory,  
        meta: { 
          title: 'Order History - Cigar Finder', 
          description: 'Check the status of your past orders and track your purchase history.', 
          needsAuth: true 
        } 
      },
      { 
        path: '/restore_password/:token', 
        component: TheLanding, 
        name: 'restore', 
        meta: { 
          title: 'Restore Password - Cigar Finder', 
          description: 'Reset your password to regain access to your account on our website.' 
        } 
      },
      { 
        path: '/privacy-policy', 
        component: PrivacyPolicy, 
        meta: { 
          title: 'Privacy Policy - Cigar Finder', 
          description: 'Read our privacy policy to understand how we handle your personal data and information.' 
        } 
      },
      { 
        path: '/terms-and-conditions', 
        component: TermConditions, 
        meta: { 
          title: 'Terms and Conditions - Cigar Finder', 
          description: 'Understand the terms and conditions for using our website and services.' 
        } 
      },
      { 
        path: '/help', 
        component: HelpContact, 
        meta: { 
          title: 'Help & Contact - Cigar Finder', 
          description: 'Need help? Contact our support team or browse our FAQs for assistance.' 
        } 
      },
      { 
        path: '/blog', 
        component: BlogPage
      },
      { 
        path: '/blog/:slug', 
        component: BlogDetailsPage, 
        meta: { 
          title: 'Blog Details - Cigar Finder', 
          description: 'Read detailed blog articles on various topics, tips, and insights from our experts.' 
        } 
      },
      { 
        path: '/news', 
        component: NewsPage, 
        meta: { 
          title: 'News | Cigar Finder', 
          description: 'Stay informed with the latest cigar news on Cigar Finder. Discover industry updates, new releases, and expert insights to enhance your cigar knowledge and experience!' 
        } 
      },
      { 
        path: '/news/:slug', 
        component: BlogDetailsPage, 
        meta: { 
          title: 'News Details - Cigar Finder', 
          description: 'Read the latest news articles and stay informed about recent events and updates.' 
        } 
      },
      { 
        path: '/advertise-with-us', 
        component: AdvertiseWithUs, 
        meta: { 
          title: 'Advertise with Us - Cigar Finder', 
          description: 'Learn more about advertising opportunities with our website to reach a wider audience.' 
        } 
      },
      { 
        path: '/coupons', 
        component: GetCouponsPage, 
      },
      { 
        path: '/coupon-detail/:slug', 
        component: CouponDetailsPage, 
      }
    ]
    ,
    /*eslint no-unused-vars: ["error", { "args": "none" }]*/
    scrollBehavior (to, from, savedPosition) {
      return { 
        top: 0, 
        behavior: 'smooth' 
      }
    }
});

router.beforeEach(function(to, from, next) {
  if (to.meta.needsAuth && !store.getters.isAuthenticated) {
    store.dispatch('registrationModalToggle', true);
    next('/');
  } else {
    next();
  }
});

router.afterEach(() => {
  setTimeout(() => {
    initCJDeepLinks();
  }, 1500)
});

trackRouter(router);

export default router;
