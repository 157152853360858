<template>
  <teleport to='head'>
    <title>{{ titleData }}</title>
    <meta name="description" :content="descriptionData">
  </teleport>
</template>

<script>
import { baseUrl } from '../../modules/utilities';

export default {
  props: {
    titleProp: String,
    descriptionProp: String,
    pageType: String
  },
  data() {
    return {
      title: "",
      description: ""
    }
  },
  computed: {
    titleData() {
      return this.title || this.titleProp;
    },
    descriptionData() {
      return this.description || this.descriptionProp;
    },
  },
  async created() {
    if (this.pageType) await this.loadMeta();
  },
  methods: {
    async loadMeta() {
      const response = await fetch(
        `${baseUrl}api/v1/get_meta?page_type=${this.pageType}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = await response.json();

      if (!response.ok) return;
      if (responseData?.meta?.title) this.title = responseData.meta.title;
      if (responseData?.meta?.description) this.description = responseData.meta.description;
    }
  },
}
</script>
