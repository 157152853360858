import { fetchData } from "@/modules/utilities";
import { baseUrl } from "../../../modules/utilities";

export default {
  async fetchCouponDetails(
    context,
    { couponResourceId, sortOrder = "", tag = "", page = 1 }  
  ) {
    let url = `${baseUrl}api/v1/coupons/get_coupons?coupon_resource_id=${couponResourceId}&page=${page}`; 

    if (sortOrder) {
      url += `&sort=${sortOrder}`;
    }

    if (tag) {
      url += `&tag=${tag}`;
    }

    let responseData = [];

    responseData = await fetchData(url, null, context.getters.token);
    context.commit("setCouponDetails", responseData);
  },
};
