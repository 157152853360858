import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    state() {
      return {
        brands: null
      };
    },
    mutations,
    actions,
    getters
  }
