export default {
  user(state) {
    return state.currentUser;
  },
  croppedAvatar(state) {
    return state.croppedAvatar
  },
  loader(state) {
    return state.loader
  }
};
