import { createApp } from 'vue';

import router from './router'
import store from './store/index';
import App from './App';
import BaseSpinner from './components/ui/BaseSpinner';
import TestAdsBanner from './components/layout/TestAdsBanner';
import BaseProfileWrapper from './components/ui/BaseProfileWrapper';
import BaseRating from './components/ui/BaseRating';
import BaseMeta from './components/ui/BaseMeta';

import ClickOutside from './directives/ckick-outside';

import GAuth from 'vue3-google-oauth2';
import Toaster from "@meforma/vue-toaster";
import Pagination from 'v-pagination-3';
import VueLazyloadNext from 'vue-lazyload-next';
import VueGtag from 'vue-gtag-next'
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

//Global Styles
import './styles/TheFilters.scss';
import './styles/ActiveFilters.scss';
import './styles/MobileControl.scss';
import './styles/ProductsListHeader.scss';
import './styles/WrappersStyles.scss';

const app = createApp(App);

const gAuthOptions = { 
  clientId: '353532333871-ouadcgink0ant3sodqscc3ltg4ef23i7.apps.googleusercontent.com', 
  scope: 'profile email', 
  prompt: 'select_account', 
  fetch_basic_profile: false 
}

app.use(router);
app.use(store);

app.use(GAuth, gAuthOptions);
app.use(Toaster);
app.use(VueLazyloadNext);
if (process.env.VUE_APP_CIGAR_FINDER_API == "https://hiscigar.com/")
{
  app.use(VueGtag, {
    property: {
      id: "G-9K136YLXRK",
    }
  });
}


app.component('base-spinner', BaseSpinner);
app.component('base-profile-wrapper', BaseProfileWrapper);
app.component('test-ads-banner', TestAdsBanner);
app.component('pagination', Pagination);
app.component('base-rating', BaseRating);
app.component('base-meta', BaseMeta);

app.directive("click-outside", ClickOutside);
app.use(VueAwesomePaginate);
app.mount('#app');


router.beforeEach((to, from, next) => {
  // Get the current domain dynamically
  const currentDomain = window.location.origin;
    console.log(to.params.slug);
    
    // Update the meta title
    if (to.meta.title) {
      document.title = to.meta.title;
    }
  
    // Update the meta description
    let metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
      metaDescription.setAttribute('content', to.meta.description || '');
    } else {
      metaDescription = document.createElement('meta');
      metaDescription.setAttribute('name', 'description');
      metaDescription.setAttribute('content', to.meta.description || '');
      document.head.appendChild(metaDescription);
    }

  // Update the canonical link
  let link = document.querySelector("link[rel='canonical']");
  if (link) {
    link.setAttribute('href', `${currentDomain}${to.path}`);
  } else {
    link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', `${currentDomain}${to.path}`);
    document.head.appendChild(link);
  }
  next();
});
