import { fetchData } from "@/modules/utilities";
import { baseUrl } from "../../../modules/utilities";

export default {
  async loadCouponsRecommended(context) {
    try {
      const response = await fetchData(`${baseUrl}api/v1/coupons/carousel_coupons`, null, context.getters.token);
      context.commit('setCouponsRecommended', response.coupons);
    } catch (err) {
      console.log(err);
    }
  },
};
