import { fetchData } from "@/modules/utilities";
import { baseUrl } from "../../../modules/utilities";

export default {
  async getCouponsList(context) {
    try {
      const response = await fetchData(`${baseUrl}api/v1/coupons/get_coupons_retailers`, null, context.getters.token);
      context.commit('setCouponsList', response.coupons);
    } catch (err) {
      console.log(err);
    }
  },
};
