<template>
    <section id="couponRecommended" v-show="couponsRecommendedList.length > 0" :style="{ margin: margin || '50px 0' }">
        <div class="wrapper">
            <div class="text_block">
                <h2 class="accent_text">
                    {{ accent_text }}
                    <br>
                    <span class="title">{{ title }}</span>
                </h2>
            </div>
        </div>

        <div class="wrapper max-width">
            <div class="left arrow" @click="slide('left')">
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1L1 9L9 17" stroke="#6C3F13" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>

            <div class="carousel-wrapper">
                <div class="carousel-container" ref="container">
                    <div v-for="item in couponsRecommendedList" :key="item.id" class="card"  @click="redirectToDetail(item.click_url)">
                        <div class="card-image">
                            <img :src="item.image_url ? item.image_url : defaultImage" :alt="item.name" />
                        </div>
                        <div class="card-title">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="right arrow" @click="slide('right')">
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 17L9 9L1 1" stroke="#6C3F13" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
        </div>
    </section>
</template>



<script>
import defaultImage from "../../assets/svgs/coupons.svg";

export default {
    props: {
        items: Array,
        accent_text: String,
        title: String,
        margin: String,
    },
    data() {
        return {
            defaultImage,
            autoSlideInterval: null,
        };
    },
    computed: {
        couponsRecommendedList() {
            const originalList = this.$store.getters.couponsRecommended;

            if (originalList.length >= 10) {
                return originalList;
            }
            //added for dummy data aleast 10
            return [...originalList, ...originalList, ...originalList, ...originalList, ...originalList, ...originalList, ...originalList, ...originalList, ...originalList].slice(0, 10);
        },
    },
    created() {
        this.$store.dispatch("loadCouponsRecommended");
        this.startAutoSlide();
    },
    beforeUnmount() {
        this.stopAutoSlide();
    },
    methods: {
        slide(direction) {
            const container = this.$refs.container;
            const scrollAmount = container.offsetWidth;

            if (direction === "right") {
                container.scrollBy({
                    left: scrollAmount,
                    behavior: "smooth",
                });

                if (
                    container.scrollLeft + container.offsetWidth >=
                    container.scrollWidth
                ) {
                    setTimeout(() => {
                        container.scrollTo({
                            left: 0,
                            behavior: "auto",
                        });
                    }, 500);
                }
            } else if (direction === "left") {
                container.scrollBy({
                    left: -scrollAmount,
                    behavior: "smooth",
                });

                if (container.scrollLeft <= 0) {
                    setTimeout(() => {
                        container.scrollTo({
                            left: container.scrollWidth,
                            behavior: "auto",
                        });
                    }, 500);
                }
            }
        },
        startAutoSlide() {
            this.autoSlideInterval = setInterval(() => {
                this.slide("right");
            }, 7000);
        },
        stopAutoSlide() {
            clearInterval(this.autoSlideInterval);
        },
        redirectToDetail(url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        console.error("URL is missing");
      }
    },
    },
};

</script>


<style lang="scss" scoped>
#couponRecommended {

    .wrapper {
        margin: 0 auto;
        text-align: center;
    }

    .text_block {
        margin-bottom: 20px;

        h2.accent_text {
            font-family: 'Parisienne', cursive;
            font-size: 28px;
            line-height: 36px;
            color: #6c3f13;

            .title {
                font-family: 'Lato', sans-serif;
                margin-top: 10px;
                font-size: 32px;
                font-weight: 800;
                line-height: 48px;
                color: #393939;
                text-transform: uppercase;
                display: block;
            }
        }
    }

    .carousel-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .carousel-container {
        display: flex;
        gap: 20px;
        transition: all 0.3s ease-in-out;
        overflow-x: scroll;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .card {
        flex: 0 0 calc(25% - 20px);
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease, box-shadow 0.2s ease;
        cursor: pointer;
        position: relative;
        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        .card-image img {
            width: 100%;
            height: 300px;
            object-fit: fill;
            object-position: top;
        }

        .card-title {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            padding: 10px;
            color: #393939;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: left;
            background: #ffffffa6;
            backdrop-filter: blur(5px);
            color: #472818eb;

        }
    }

    .arrow {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #f2e2d3;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        top: 45%;
        cursor: pointer;

        &:hover {
            svg path {
                stroke: #f2e2d3;
            }

            background-color: #6c3f13;
        }
    }

    .left {
        left: -65px;
    }

    .right {
        right: -65px;
    }

    @media screen and (max-width: 720px) {
        .arrow {
            display: none;
        }

        .card {
            flex: 0 0 calc(50% - 20px);
        }
    }

    @media screen and (max-width: 720px) {
        .card {
            flex: 0 0 calc(100% - 12px);
        }
        .carousel-container{
            gap: 10px;
        }
    }
}
</style>
